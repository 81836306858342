import React, { useState } from 'react';
import { FaDice, FaUser, FaLock } from 'react-icons/fa';

const LuxuryLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, password);
  };

  return (
    <div className="luxury-login-container" style={{
      background: 'linear-gradient(45deg, #1a1a1a, #1a1a1a)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: '"Times New Roman", serif',
    }}>
      <div className="login-box" style={{
        background: 'linear-gradient(45deg, #B8860B, #FFD700)',
        padding: '40px',
        borderRadius: '15px',
        boxShadow: '0 0 30px rgba(255, 215, 0, 0.5)',
        textAlign: 'center',
        maxWidth: '400px',
        width: '90%',
      }}>
        <FaDice size={60} color="#000" style={{ marginBottom: '20px' }} />
        <h1 style={{
          color: '#000',
          fontSize: '2.5em',
          marginBottom: '30px',
          textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
        }}>
          Power Luxury Casino
        </h1>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ position: 'relative', width: '100%', marginBottom: '20px' }}>
            <FaUser size={20} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#B8860B' }} />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              style={{
                width: '100%',
                padding: '12px 40px',
                fontSize: '1em',
                border: 'none',
                borderRadius: '25px',
                background: 'rgba(0,0,0,0.1)',
                color: '#000',
                outline: 'none',
              }}
            />
          </div>
          <div style={{ position: 'relative', width: '100%', marginBottom: '30px' }}>
            <FaLock size={20} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#B8860B' }} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              style={{
                width: '100%',
                padding: '12px 40px',
                fontSize: '1em',
                border: 'none',
                borderRadius: '25px',
                background: 'rgba(0,0,0,0.1)',
                color: '#000',
                outline: 'none',
              }}
            />
          </div>
          <button type="submit" style={{
            background: '#000',
            color: '#FFD700',
            border: 'none',
            padding: '12px 40px',
            fontSize: '1.2em',
            borderRadius: '25px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            fontWeight: 'bold',
            boxShadow: '0 0 10px rgba(0,0,0,0.3)',
          }}>
            Enter Casino
          </button>
        </form>
      </div>
    </div>
  );
};

export default LuxuryLogin;
import React, { useEffect, useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import './Wheel.css';

function SpinWheel({ numbers, spinSpeed, winner, onStopSpinning }) {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    if (winner !== null) {
      setMustSpin(true);
      setPrizeNumber(numbers.indexOf(winner));
      setIsSpinning(true);
    }
  }, [winner, numbers]);

  const handleStopSpinning = () => {
    setMustSpin(false);
    onStopSpinning();
    setIsSpinning(false);
  };

  const getSegmentColor = (index) => {
    return index % 2 === 0 ? '#FFD700' : '#000000';
  };

  const data = numbers.map((number, index) => ({
    option: `${number}`,
    style: {
      backgroundColor: getSegmentColor(index),
      textColor: index % 2 === 0 ? '#000000' : '#FFD700',
      fontWeight: 'bold',
      marginTop: '10px',
      fontSize: 36,
      fontFamily: 'Arial, sans-serif',
    },
  }));

  return (
    <div className="wheel-container my-2" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh',
      background: 'radial-gradient(circle, #2C3E50, #1A1A1A)',
      boxShadow: '0 0 20px rgba(255, 215, 0, 0.3)',
      borderRadius: '10px',
      padding: '20px',
    }}>
      <div style={{
        position: 'relative',
        width: '80vmin',
        height: '80vmin',
        maxWidth: '600px',
        maxHeight: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          position: 'absolute',
          width: '110%',
          height: '110%',
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #FFD700, #FFA500)',
          filter: 'blur(10px)',
          opacity: '0.7',
          zIndex: 0,
        }} />
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          spinDuration={1}
          wheelSize={420}
          onStopSpinning={handleStopSpinning}
          radiusLineWidth={2}
          radiusLineColor="#FFD700"
          outerBorderColor="#FFD700"
          outerBorderWidth={10}
          innerBorderColor="#FFD700"
          innerBorderWidth={20}
          innerRadius={20}
          textDistance={80}
          fontSize={36}
          perpendicularText={true}
          textColors={['#000000', '#FFD700']}
          backgroundColors={['#FFD700', '#000000']}
          spinningSpeed={spinSpeed * 1000}
        />
        {!isSpinning && winner !== null && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#FFD700',
            padding: '20px',
            borderRadius: '10px',
            fontSize: '24px',
            fontWeight: 'bold',
            zIndex: 10,
            boxShadow: '0 0 20px rgba(255, 215, 0, 0.5)',
          }}>
            Winner: {winner}
          </div>
        )}
      </div>
    </div>
  );
}

export default SpinWheel;
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function WinnerPopup({ winner, bettingAmount, numberOfPlayers, onClose }) {
  const showWinnerPopup = () => {
    MySwal.fire({
      title: 'Congratulations!',
      html: `
        <div style="background: linear-gradient(45deg, #B8860B, #FFD700); padding: 20px; border-radius: 10px; box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);">
          <h1 style="color: #000; font-family: 'Times New Roman', serif; font-size: 2.5em; text-shadow: 2px 2px 4px rgba(0,0,0,0.3); margin-bottom: 20px;">
            The winner is: ${winner}
          </h1>
          <h2 style="color: #000; font-family: Arial, sans-serif; font-size: 1.8em; margin-bottom: 10px;">
            Prize Amount:
          </h2>
          <h3 style="color: #006400; font-family: 'Courier New', monospace; font-size: 2.2em; font-weight: bold;">
            ${(numberOfPlayers * bettingAmount * 0.8).toFixed(2)} ብር
          </h3>
        </div>
      `,
      background: '#000000',
      backdrop: `
        rgba(0,0,0,0.8)
        url("/images/confetti.gif")
        left top
        no-repeat
      `,
      confirmButtonText: 'Collect Winnings',
      confirmButtonColor: '#B8860B',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showClass: {
        popup: 'animate__animated animate__zoomIn',
      },
      hideClass: {
        popup: 'animate__animated animate__zoomOut',
      },
      customClass: {
        title: 'luxurious-title',
        confirmButton: 'luxurious-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onClose();
      }
    });
  };

  React.useEffect(() => {
    // Add custom styles to the document
    const style = document.createElement('style');
    style.textContent = `
      .luxurious-title {
        color: #FFD700 !important;
        font-family: 'Times New Roman', serif !important;
        font-size: 3em !important;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.5) !important;
      }
      .luxurious-button {
        background: linear-gradient(45deg, #B8860B, #FFD700) !important;
        color: #000 !important;
        font-family: Arial, sans-serif !important;
        font-size: 1.2em !important;
        font-weight: bold !important;
        border: none !important;
        box-shadow: 0 0 10px rgba(255, 215, 0, 0.5) !important;
      }
      .luxurious-button:hover {
        background: linear-gradient(45deg, #FFD700, #B8860B) !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      // Clean up the added styles when the component unmounts
      document.head.removeChild(style);
    };
  }, []);

  return <div>{showWinnerPopup()}</div>;
}

export default WinnerPopup;